import { Link } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors, maxContentWidth } from '../../constants';
import { rhythm } from '../../utils/typography';

export const BlogCardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
`;

export const BlogCardHeader = styled.h3`
  margin-top: 0;
  margin-bottom: ${rhythm(1 / 4)};
  font-size: 1.2em;
`;
export const BlogCardDate = styled.small`
  color: rgba(25, 25, 25, 0.9);
`;

const BlogCardCommonProps = css`
  // border-top: 1px solid #d3d3d1;
  // box-shadow: rgba(10, 16, 34, 0.25) 0px 0px 1px 1px;
  // background: rgb(250, 250, 250);
  // margin-bottom: 1rem;
  margin-right: 1rem;
  width: ${maxContentWidth}px;
  color: ${colors.a.normal};
  @media (max-width: 960px) {
    display: block;
    min-width: 94vw;
    max-width: 94vw;
  }
`;

export const BlogCard = styled(Link)`
  ${BlogCardCommonProps}
  padding: 1rem 0 0;
  &:hover {
    // box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 1px 1px;
    color: ${colors.a.hover};
    // border: 1px solid #d3d3d1;
  }
`;

export const BlogCardTitleOnly = styled(Link)`
  ${BlogCardCommonProps}
  padding: 0.25rem 1rem;
  margin: 0;
  box-shadow: black 0 0 1px 0;
  &:hover {
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 1px 1px;
    color: ${colors.a.hover};
  }
`;

export const BlogCardExcerpt = css`
  font-size: 1em;
`;
