import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { LinkStyle } from '../layout/style';
import { maxNavLength } from '../../constants';

const PageAnchor = styled.a`
  ${LinkStyle}
  font-weight: 410;
`;

export default function Welcome({
  redditStopGamingPostUrl,
  social: { reddit },
}) {
  return (
    <div
      css={css`
        @media (max-width: ${maxNavLength}px) {
          width: 92vw;
        }
      `}
    >
      <h2
        css={css`
          font-weight: 500;
          text-align: center;
        `}
      >
        Hey my name is Mygo
      </h2>
      <p>
        And you might’ve seen me around on{' '}
        <PageAnchor rel={'noopener'} target={'_blank'} href={reddit}>
          reddit
        </PageAnchor>
        . I used to play a lot of games and I used to take it really seriously.
        I quit on the 17th of March 2014 and wrote about it here:{' '}
        <PageAnchor
          rel={'noopener'}
          target={'_blank'}
          href={redditStopGamingPostUrl}
        >
          (how I changed and quit after 28 years gaming).
        </PageAnchor>{' '}
        I distinctly remember the day I quit gaming I was able to delete
        everything game related without any emotional resistance. And I remember
        realizing from that moment what changed and needing to express that,
        which I eventually did{' '}
        <Link
          css={css`
            ${LinkStyle}
            font-weight: 410;
          `}
          to={'explanations/how/change-yourself/'}
        >
          here.
        </Link>
      </p>
      <p>
        Having said that I don't have any credentials that would make me an
        authority on any of these topics. Everything you read here is based on
        observation and discussion between peers. Over time as this blog is
        still early in its infancy I will improve it iteratively. Explanations
        will be revised. Alternate angles of explanations will be given. I hope
        whenever you read this, it's up to at least a good standard.
      </p>
    </div>
  );
}
