import React from 'react';
import { css } from '@emotion/core';
import {
  HamburgerSystem,
  NavMenuContainerCss,
  NavMenuOpenWrapper,
  NavMenuLinkContent,
  NavMenuLink,
  NavMenuContentWrapper,
  HamburgerButton,
  HamburgerButtonSvgStyle,
  GetTransparentButton,
} from './style';
import SVG from 'react-inlinesvg';
import HamburgerIcon from '../../../content/assets/icon/hamburger.svg';

const TransparentButton = GetTransparentButton(0.75);

export default function HamburgerMenu({ menuState, setMenu }) {
  return (
    <HamburgerSystem>
      <div
        css={css`
          ${NavMenuContainerCss}
          background: rgba(25,25,26, ${menuState === 'closed' ? 0 : 1});
          padding-top: 0;
          position: relative;
        `}
      >
        {menuState === 'open' && (
          <>
            <div
              css={css`
                color: white;
                background: white;
                height: 0.1rem;
                padding: 0;
                margin: 0 0 1.2rem;
              `}
            />
            <div css={NavMenuOpenWrapper} />
            <div css={NavMenuContentWrapper}>
              <NavMenuLink to={'/articles'}>
                <NavMenuLinkContent>Articles</NavMenuLinkContent>
              </NavMenuLink>
              <NavMenuLink to={'/explanations'}>
                <NavMenuLinkContent>Explanations</NavMenuLinkContent>
              </NavMenuLink>
              <NavMenuLink to={'/blog'}>
                <NavMenuLinkContent>Blog Posts</NavMenuLinkContent>
              </NavMenuLink>
            </div>
          </>
        )}
      </div>
      {menuState === 'open' && (
        <>
          <TransparentButton
            aria-label={'transparent close button'}
            onClick={() => {
              setMenu('closed');
            }}
          />
        </>
      )}
      <HamburgerButton
        aria-label={'menu button'}
        onClick={() => {
          setMenu(menuState === 'open' ? 'closed' : 'open');
        }}
      >
        <SVG css={HamburgerButtonSvgStyle} src={HamburgerIcon} />
      </HamburgerButton>
    </HamburgerSystem>
  );
}
