import React from 'react';
import { css } from '@emotion/core';
import {
  BlogCard,
  BlogCardContainer,
  BlogCardTitleOnly,
  BlogCardHeader,
  BlogCardDate,
  BlogCardExcerpt,
} from './style';
import { maxContentWidth } from '../../constants';
export default function BlogListing({ heading, posts, selectedViewType }) {
  return (
    <>
      <div
        css={css`
          background: black;
          color: white;
          height: 5rem;
          // width: 100vw;
          // margin: 0 calc(-50vw + 50%);
          // border-radius: 6px;
          margin: 0 -1rem 1rem;
          width: ${maxContentWidth}px;
          box-shadow: 2pxpx 2px 2px rgba(0, 0, 0, 0.15);
        `}
      >
        <h4
          css={css`
            padding: 2rem 1rem;
            @media (min-width: ${maxContentWidth}px) {
              margin: 0 calc(-50vw + 50% + (50vw - ${maxContentWidth / 2}px));
            }
          `}
        >
          {heading}
        </h4>
      </div>
      <BlogCardContainer>
        {posts.map(({ node }, index) => {
          const title = node.frontmatter.title || node.fields.slug;
          if (selectedViewType === 'card') {
            return (
              <BlogCard
                key={node.fields.slug}
                to={node.fields.slug}
                css={css`
                  border-top: ${index === 0 ? 0 : '1px solid #d3d3d1;'};
                `}
              >
                <BlogCardHeader>
                  <span>{title}</span>
                </BlogCardHeader>
                <div>
                  <BlogCardDate>
                    {node.frontmatter.longDate} | {node.fields.type}
                  </BlogCardDate>
                  <p
                    css={BlogCardExcerpt}
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </div>
              </BlogCard>
            );
          }
          return (
            <BlogCardTitleOnly key={node.fields.slug} to={node.fields.slug}>
              <BlogCardDate>{node.frontmatter.shortDate}</BlogCardDate> -{' '}
              <span>{title}</span>
            </BlogCardTitleOnly>
          );
        })}
      </BlogCardContainer>
    </>
  );
}
