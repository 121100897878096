import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  PageContainerStyle,
  HeaderBackground,
  HeaderWrapper,
  HomeLinkWrapper,
  HomeLink,
  NavWrapper,
  NavContent,
  ContentWrapper,
  FooterWrapper,
  CopyrightWrapper,
  CopyrightSpan,
} from './style';
import HamburgerMenu from '../hamburgermenu';

const navLinks = [
  { text: 'Articles', key: 'nav_articles', route: '/articles' },
  { text: 'Explanations', key: 'nav_explanations', route: '/explanations' },
  { text: 'Blog Posts', key: 'nav_blog_posts', route: '/blog' },
];

export default function Layout({ title, children }) {
  const [menuState, setMenu] = useState('closed');
  return (
    <div
      css={css`
        ${PageContainerStyle}
        position: ${menuState === 'closed' ? 'relative' : 'fixed'};
      `}
    >
      <HeaderBackground>
        <HeaderWrapper>
          <HomeLinkWrapper>
            <HomeLink to={`/`}>{title.toUpperCase()}</HomeLink>
          </HomeLinkWrapper>
          <NavWrapper>
            {navLinks.map(item => (
              <NavContent key={item.key} to={item.route}>
                {item.text}
              </NavContent>
            ))}
          </NavWrapper>
          <HamburgerMenu menuState={menuState} setMenu={setMenu} />
        </HeaderWrapper>
      </HeaderBackground>
      <ContentWrapper>
        <main>{children}</main>
      </ContentWrapper>
      <FooterWrapper>
        <CopyrightWrapper>
          <CopyrightSpan>
            Copyright © {new Date().getFullYear()} Mygo explains
          </CopyrightSpan>
        </CopyrightWrapper>
      </FooterWrapper>
    </div>
  );
}
