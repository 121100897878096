import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Welcome from '../components/welcome';
import BlogListing from '../components/bloglisting';

class BlogIndex extends React.Component {
  state = {
    selectedViewType: 'card',
  };
  render() {
    const { recommended } = this.props.data;
    const { recent } = this.props.data;
    const siteMetadata = this.props.data.site.siteMetadata;
    const siteTitle = siteMetadata.title;
    const { selectedViewType } = this.state;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        {Welcome(siteMetadata)}
        <BlogListing
          heading={`Recommended Reads`}
          posts={recommended.edges}
          selectedViewType={selectedViewType}
        />
        <BlogListing
          heading={`Recently Added`}
          posts={recent.edges}
          selectedViewType={selectedViewType}
        />
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          reddit
        }
        redditStopGamingPostUrl
      }
    }
    recommended: allMarkdownRemark(
      filter: { frontmatter: { recommended: { ne: null } } }
      sort: { fields: [frontmatter___recommended], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            longDate: date(formatString: "DD MMMM, YYYY")
            shortDate: date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
    recent: allMarkdownRemark(
      filter: { frontmatter: { recommended: { eq: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            longDate: date(formatString: "DD MMMM, YYYY")
            shortDate: date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`;
